import {
  Container,
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  Icon,
  Image,
  useTheme,
} from '@chakra-ui/react';
import { useContext, useState } from 'react';
import NextLink from 'next/link';
import { IoIosCart } from 'react-icons/io';
import { FaStoreAlt } from 'react-icons/fa';
import { Announcement, SocialFansBar } from 'src/components/molecules';
import { BLACK, CONTAINER_MAX_WIDTH_1, WHITE } from 'src/constants/ui';
import { ProfileMenu } from 'src/components/atoms';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { ErrorBoundary } from 'src/components/system';
import { SocialProfile } from 'src/api/v1-api';
import { useRouter } from 'next/router';
import { srcFromCDN } from 'src/utils/files';
import GlobalSearch from 'src/components/atoms/global-search';
import { useGlobalSearch } from 'src/contexts/globalSearch';

const get_total_social_count_rounded = (social_profiles: SocialProfile[]) => {
  if (social_profiles.length === 0) {
    return 0;
  }

  const count = social_profiles.reduce((acc, curr) => acc + (curr?.total_followers || 0), 0);

  if (count >= 10000 && count < 1000000) {
    return `${Math.round(count / 1000)}K`;
    // values greater than a million simplified with 'M'
  } else if (count >= 1000000) {
    const milCount = (Math.round(count * 10) / 10000000).toFixed(1);
    const milCountParsed = parseFloat(milCount);
    return `${milCountParsed % 1 === 0 ? milCountParsed.toFixed(0) : milCountParsed.toFixed(1)}M`;
  }
  return count;
};

const Header = (): JSX.Element => {
  const { marketplace, logoIsCircle } = useContext(MarketplaceContext);
  const { setSearchQuery } = useGlobalSearch();
  const theme = useTheme();
  const router = useRouter();

  const [announcementOpened, setAnnouncementOpened] = useState(true);

  const isAdminPage = router.pathname.includes('/admin');
  const showAnnouncement = marketplace?.functional?.announcement && announcementOpened;
  const bgColor = theme.colors.navBG ?? WHITE;
  const textColor = theme.colors.navText ?? BLACK;

  const total_social_count = get_total_social_count_rounded(marketplace?.social_profiles || []);
  const show_social_media_audience = marketplace?.functional?.show_total_social_media_audience;

  return (
    <>
      <Box boxShadow="base" bgColor={bgColor} color={textColor} zIndex={10}>
        <Container maxW={CONTAINER_MAX_WIDTH_1} centerContent py="10px">
          <Flex w="full" justifyContent="space-between" alignItems="center" h="full">
            <Box width={{ base: 'calc(100% - 80px)', lg: 'auto' }} overflow={'hidden'}>
              <NextLink
                href="/"
                style={{
                  width: '100%',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
                passHref
                onClick={() => setSearchQuery('')}
              >
                <Flex gap={'1rem'} alignItems="center" columnGap="8px">
                  {marketplace?.logo_image ? (
                    <Image
                      src={srcFromCDN(marketplace?.logo_image, 32)}
                      boxSize="32px"
                      width="32px"
                      height="32px"
                      alt="Logo"
                      objectFit="cover"
                      borderRadius={logoIsCircle ? 'full' : ''}
                    />
                  ) : (
                    <Icon as={FaStoreAlt} color={textColor} boxSize="6" />
                  )}
                  <Box display="contents">
                    <Heading
                      fontSize={{ sm: 'md', md: 'lg', lg: 'xl' }}
                      fontWeight="normal"
                      style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {marketplace?.name}
                    </Heading>
                  </Box>
                </Flex>
              </NextLink>
            </Box>

            <Flex
              justifyContent="space-between"
              alignItems="center"
              columnGap={{ base: '12px', lg: '24px' }}
            >
              <Flex
                justifyContent="space-between"
                alignItems="center"
                columnGap={{ base: '10px', lg: '16px' }}
                fontSize={{ base: '20px', lg: '32px' }}
              >
                <GlobalSearch totalSocialCount={total_social_count} />
                <Box pt={{ base: '10px', lg: '0' }}>
                  <NextLink href="/cart" passHref>
                    <Icon as={IoIosCart} color={textColor} boxSize="6" />
                  </NextLink>
                </Box>
                <Center height={{ base: '32px', lg: '40px' }}>
                  <Divider orientation="vertical" border="1/2px" borderColor="gray.300" />
                </Center>
                <ErrorBoundary
                  displayAsToast={true}
                  toastId="profile-menu-error"
                  toastTitle="There was a problem authenticating"
                >
                  <ProfileMenu totalSocialCount={total_social_count} />
                </ErrorBoundary>
              </Flex>
            </Flex>
          </Flex>
          {show_social_media_audience && (
            <Box alignSelf="start">
              <SocialFansBar marketplace={marketplace} total_social_count={total_social_count} />
            </Box>
          )}
        </Container>
      </Box>
      {!isAdminPage && showAnnouncement && (
        <Container maxW={CONTAINER_MAX_WIDTH_1} pt={{ base: '8px', lg: '12px' }}>
          <Announcement
            text={marketplace?.functional?.announcement ?? ''}
            onClose={() => setAnnouncementOpened(false)}
          />
        </Container>
      )}
    </>
  );
};

export default Header;
